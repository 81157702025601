<template>
  <div class='register'>
    <div class='uxg'>Sign Up</div>
    <div class='reg'>
      <div class='hss'>
        <input-element class='nrt'
          type='text'
          label='First Name'
          v-model='inputs.firstName'/>
        <input-element class='nrt'
          type='text'
          label='Last Name'
          v-model='inputs.lastName'/>
      </div>
      <div class='hss'>
        <mobile-input-component class='nrt'
          label='Mobile Number'
          v-model='inputs.mobileNumber'
          :error='isMobileNumberValid ? "" : "Invalid number"'/>
        <input-element class='nrt'
          type='email'
          label='Working Email Address'
          v-model='inputs.email'/>
      </div>
      <div class='hss'>
        <input-element class='nrt'
          type='password'
          label='Password'
          v-model='inputs.password'
          :error='isPasswordMatch ? "" : " "'/>
        <input-element class='nrt'
          type='password'
          label='Confirm Password'
          v-model='inputs.confirmPassword'
          :error='isPasswordMatch ? "" : "Confirm Password does not match"'/>
      </div>
    </div>
    <div class='pcs'>
      <button-element
        :disabled='registering'
        @click='register'>
        Submit
        <progress-element class='rwy'
          :active='registering'/>
      </button-element>
    </div>
    <flash-message-component
      ref='errorDialog'
      type='error'>
      <template v-if='errorCode === "invalid-email"'>The email address is invalid.</template>
      <template v-if='errorCode === "weak-password"'>The password must be 6 characters long or more.</template>
      <template v-if='errorCode === "email-already-in-use"'>The email address is already in use by another account.</template>
      <template v-if='errorCode === "unknown"'>Oops! Something went wrong. Please try again later.</template>
    </flash-message-component>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import FlashMessage from '@/components/flash-message/flash-message.vue'
  import MobileInput from '@/components/mobile-input/mobile-input.vue'
  import store from '@/store'
  import {
    CREATE_USER,
    GET_USER,
    SEND_EMAIL_VERIFICATION,
    UPDATE_PROFILE
  } from '@/store/action-types'

  export default {
    components: {
      'flash-message-component': FlashMessage,
      'mobile-input-component': MobileInput
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch(GET_USER)
      if (user && user.emailVerified) {
        next({ name: 'profile' })
        return
      }
      next()
    },

    setup () {
      let router = useRouter()
      let store = useStore()

      let registering = ref(false)
      let errorDialog = ref(null)
      let errorCode = ref('')
      let inputs = ref({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        password: '',
        confirmPassword: ''
      })

      let isMobileNumberValid = computed(() => {
        let mobileNumber = inputs.value.mobileNumber
        if (!mobileNumber) return true
        if (!mobileNumber.split(' ')[1]) return true
        return /^\+[1-9]\d{0,2} \d{1,12}$/.test(mobileNumber)
      })

      let isPasswordMatch = computed(() => {
        let { password, confirmPassword } = inputs.value
        if (!confirmPassword) return true
        return password === confirmPassword
      })

      let validate = () => {
        let {
          firstName,
          lastName,
          mobileNumber,
          email,
          password,
          confirmPassword
        } = inputs.value

        if (!firstName) return null
        if (!lastName) return null
        if (!/^\+[1-9]\d{0,2} \d{1,12}$/.test(mobileNumber)) return null
        if (!email) return null
        if (!password) return null
        if (password !== confirmPassword) return null

        return {
          email,
          password,
          profile: {
            firstName,
            lastName,
            mobileNumber
          }
        }
      }

      let register = async () => {
        let data = validate()
        if (!data) return
        let { email, password, profile } = data

        try {
          registering.value = true
          await store.dispatch(CREATE_USER, { email, password })
          await store.dispatch(UPDATE_PROFILE, { profile })
          await store.dispatch(SEND_EMAIL_VERIFICATION, { profile })

          router.push({ name: 'start' })
        } catch (error) {
          switch (error.code) {
            case 'auth/invalid-email':
              errorCode.value = 'invalid-email'
              break
            case 'auth/weak-password':
              errorCode.value = 'weak-password'
              break
            case 'auth/email-already-in-use':
              errorCode.value = 'email-already-in-use'
              break
            default:
              errorCode.value = 'unknown'
          }
          errorDialog.value.show()
        } finally {
          registering.value = false
        }
      }

      return {
        registering,
        errorDialog,
        errorCode,
        inputs,
        isMobileNumberValid,
        isPasswordMatch,
        register
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .uxg {
    margin-top: 16px;
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .reg {
    margin-top: 24px;
    padding: 24px;
    background-color: white;
    border-radius: 4px;
  }

  .hss {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  .nrt {
    width: 100%;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  .pcs {
    margin-top: 24px;
    text-align: center;
  }

  .rwy {
    margin-left: 16px;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .uxg {
      text-align: center;
    }

    .reg {
      margin-top: 32px;
      padding: 32px;
    }

    .hss {
      flex-direction: row;

      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    .nrt {
      &:not(:first-child) {
        margin: 0 0 0 32px;
      }
    }

    .pcs {
      margin-top: 32px;
    }
  }
</style>
